:root {
    overflow: hidden;
    min-height: 100%;
    width: 100%;
    margin: auto;
}

.App {
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}

.middle-section {
    display: flex;
    flex-direction: column;
    /* height: 82vh; */
}

.right-panel {
    height: inherit;
}

h1 {
    padding-top: 4px;
    font-size: 16px;
}
/* 
.MuiTypography-h2 {
    padding-top: 4px;
    font-size: 1rem !important;
} */

.icon {
    transition: 0.2s;
}

.icon:hover {
    color: #17a2b8;
}

.modalMap .leaflet-container {
    height: 50vh;
    width: 100%;
}

.icon {
    position: absolute;
    right: 35vw;
}

.TabIndicator-colorSecondary-151 {
    background-color: #fff !important;
}

.MuiNotchedOutline-root-122 {
    border-color: #fff !important;
}

.MuiFormLabel-root-85.MuiFormLabel-focused-86 {
    color: #fff !important;
    background-color: #1e87e5 !important;
}

.MuiInputBase-input-115 {
    color: #fff !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* 
.search-field {
    width: 100%;
} */
/* Media Querys */
.search-field {
    width: 100%;
}

/* Media Querys */
@media only screen and (min-width: 960px) {
    .search-field {
        width: 33%;
    }
    .list-container {
        height: 86vh;
    }
    .right-panel {
        overflow-y: scroll;
    }
}

@media only screen and (max-width: 960px) {
    .panel-container {
        overflow-y: auto;
    }
}
/* 
.map-cluster {
    background-color: #1e87e5;
    text-align: center;
    align-content: center;
    color: "white";
    cursor: "zoom-in";
} */

.map-cluster {
    color: white;
    cursor: pointer;
    border-radius: 50%;
    background-color: #1e87e5;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid #78b7ef;

    min-width: 30px;
    min-height: 30px;
    max-width: 70px;
    max-height: 70px;
}

.banner-close{
    position: absolute;
    right: 2px;
    top: 2px;
    width: 15px;
    height: 15px;
    background-color: white;
    border: 1px solid #03c2fc;
    padding: 1px;
}