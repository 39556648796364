.group-label-button {
    height: 30px;
    min-height: 30px;
    padding-bottom: 5px;
    background: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
}

.group-label-button:focus {
    outline: none;
}

.river-item {
    background-color: rgb(139, 211, 237);
    width: 90%;
    text-align: left;
    margin: 0 3px 3px 5%;
    padding: 2% 2% 2% 2%;
    border-radius: 10px;
    position: relative;
    float: left;
}

.river-item:focus {
    outline: 0;
}

.list-river {
    height: inherit;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.list-group {
    height: inherit;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.list-item {
    /* background-color: rgb(215, 215, 215); */
    background-color: rgb(223, 223, 223);
    width: 90%;
    text-align: left;
    margin: 0 3px 3px 5%;
    padding: 2% 2% 2% 2%;
    border-radius: 10px;
    position: relative;
    float: left;
}

.list-item:focus {
    outline: none;
}

.add-icon {
    position: absolute;
    z-index: 1;
    margin-left: 31vw;
    margin-top: 2vh;
}

.filter-icon {
    position: absolute;
    z-index: 1;
    margin-left: 31vw;
    margin-top: 11vh;
}

.loader {
    width: 10%;
    padding-left: 50%;
    padding-top: 30%;
}

.noresults {
    width: 100%;
    padding-left: 40%;
    padding-top: 30%;
}

.kayaker-icon {
    height: 25px;
}
